import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const propTypes = {
  loadMore: PropTypes.func.isRequired
}

const ButtonLoadMore = (props) => <Button onClick={props.loadMore}/>

const Button = styled.button`
  background: #6a9b3a;
  color: white;
  margin-top: 58px;

  width: 139px;
  height: 53px;
  box-shadow: 1px 1.7px 3px 0 rgba(38, 38, 38, 0.13);

  outline: none;
  cursor: pointer;

  ::after {
    content: '';
    display: block;
    position: relative;
    bottom: 4px;
    left: 50px;
    width: 20px;
    height: 20px;

    border: 3px solid #ffffff;
    border-radius: 2px;
    border-top: none;
    border-right: none;

    transform: rotate(-45deg);
  }
`

export default ButtonLoadMore

ButtonLoadMore.propTypes = propTypes
