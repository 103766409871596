import React from 'react'
import PropTypes from 'prop-types'

import { getFeaturedMediaMediumSize } from 'utils'
import { Section, Container, Row, Col, Heading, Product } from 'components'

const propTypes = {
  products: PropTypes.array
}

const GuestProductsSection = props => (
  <Section>
    <Container narrow column>
      <Heading center>Produkty gościnne</Heading>

      {/* <Paragraph center>Produkty pochodzące od zaprzyjaźnionych dostawców</Paragraph> */}

      <Row mTop={48} listBottom={40}>
        {props.products.map(product => (
          <Col key={product.id} column itemsCenter small={12} medium={6} large={4}>
            <Product
              id={product.id}
              img={getFeaturedMediaMediumSize(product)}
              name={product.title.rendered}
              text={product.acf.nazwa_lacinska}
            />
          </Col>
        ))}
      </Row>
    </Container>
  </Section>
)

GuestProductsSection.propTypes = propTypes

export default GuestProductsSection
