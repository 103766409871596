import styled from 'styled-components'

export default styled.main`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  > section:first-child {
    padding-top: 0;
  }
`
