import React from 'react'

const defaultProps = {
  stroke: '#a5a5a5',
  strokeWidth: '1.5',
}

const CloseIcon = ({ stroke, strokeWidth, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      fill="none"
      fillRule="evenodd"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
      d="M1 15L15 1M1 1l14 14"
    />
  </svg>
)

CloseIcon.defaultProps = defaultProps

export default CloseIcon
