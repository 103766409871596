import React from 'react'
import wycieczki1 from 'assets/images/wycieczki1.jpg'
import wycieczki2 from 'assets/images/wycieczki2.jpg'
import wycieczki3 from 'assets/images/wycieczki3.jpg'

import { Section, Container, Div, Heading, Paragraph, Row, Col, NewsImage } from 'components'

const WycieczkiPoPoluSection = props => (
  <Section>
    <Container column itemsCenter style={{ marginBottom: 50 }}>
      <Div column itemsCenter maxWidth={827} mTop={50}>
        <Heading>Wycieczki po polu</Heading>
        <Paragraph mTop={34} center>
          Zapraszamy dzieci ze szkół i przedszkoli do spacerów po naszym polu.
        </Paragraph>
        <Paragraph mTop={34} center>
          Podczas takiej wyprawy rozmawiamy o warzywach, zdrowiu, ekologii i o wszystkim, co spotkamy na naszej drodze.
        </Paragraph>
        <Paragraph mTop={34} center>
          Szczegóły i zapisy <a href="mailto:hannamajlert@gmail.com" style={{ fontWeight: 600 }}>hannamajlert@gmail.com</a>
        </Paragraph>
      </Div>
      <Row mTop={6} width="100%" justifyCenter>
        <Col column justifyStart itemsCenter small={12} medium={6} large={4} mTop={54}>
          <NewsImage src={wycieczki1} style={{ height: '250px' }}/>
        </Col>
        <Col column justifyStart itemsCenter small={12} medium={6} large={4} mTop={54}>
          <NewsImage src={wycieczki2} style={{ height: '250px' }}/>
        </Col>
        <Col column justifyStart itemsCenter small={12} medium={6} large={4} mTop={54}>
          <NewsImage src={wycieczki3} style={{ height: '250px' }}/>
        </Col>
      </Row>
    </Container>
  </Section>
)

export default WycieczkiPoPoluSection
