import { media } from 'styles'

import { Div } from 'components'

export default Div.extend`
  width: 100%;
  max-width: ${props => (props.narrow ? 742 : 1280)}px;

  margin: 0 auto;

  padding: 0 8px;
  ${media.mediumUp`padding: 0 12px;`}
  ${media.largeUp`padding: 0 16px;`}
`
