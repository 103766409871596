import React from 'react'
import styled from 'styled-components'

import { colors } from 'styles'
import { Div } from 'components'

const MapHardCode = () => (
  <Wrapper column itemsCenter>
    <p style={{ margin: 0 }}>
      <iframe
        title="map"
        width="862"
        height="536"
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
        src="https://maps.google.pl/maps?f=q&amp;source=s_q&amp;hl=pl&amp;geocode=+&amp;q=majlert&amp;ie=UTF8&amp;hq=majlert&amp;hnear=&amp;radius=15000&amp;t=h&amp;cid=14950018120048606050&amp;ll=52.324088,20.993328&amp;spn=0.00918,0.018239&amp;z=15&amp;iwloc=A&amp;output=embed"
      />
    </p>
    <p style={{ margin: 0 }}>
      <BiggerMap
        href="https://maps.google.pl/maps?f=q&amp;source=embed&amp;hl=pl&amp;geocode=+&amp;q=majlert&amp;ie=UTF8&amp;hq=majlert&amp;hnear=&amp;radius=15000&amp;t=h&amp;cid=14950018120048606050&amp;ll=52.324088,20.993328&amp;spn=0.00918,0.018239&amp;z=15&amp;iwloc=A"
        target="_blank"
      >
      Wyświetl większą mapę
      </BiggerMap>
    </p>
  </Wrapper>
)

export default MapHardCode

const Wrapper = styled(Div)`
  width: '100%';
  max-width: 862px;
  height: 536px;
  margin-bottom: 130px;
`

const BiggerMap = styled.a`
  text-decoration: none;
  color: ${colors.green};
  font-size: 12px;
  text-align: center;
`
