import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { getFeaturedMedia } from 'utils'
import { Section, Container, Row, Col, News, ButtonLoadMore } from 'components'

export default class KalendariumSection extends Component {
  static propTypes = {
    news: PropTypes.array
  }

  static defaultProps = {
    news: []
  }

  state = {
    displayedNews: 6
  }

  render () {
    return (
      <Section>
        <Container column itemsCenter>
          <Row mTop={6}>
            {this.props.news.slice(0, this.state.displayedNews).map(item => (
              <Col column justifyStart itemsCenter small={12} medium={6} large={4} mTop={54} key={item.id}>
                <News
                  id={item.id}
                  data={item.acf.data}
                  img={getFeaturedMedia(item)}
                  title={item.title.rendered}
                  text={item.acf.opis_aktualnosci}
                  more='czytaj więcej'/>
              </Col>
            ))}
          </Row>
          <ButtonLoadMore loadMore={() => this.setState({ displayedNews: this.state.displayedNews + 3 })}/>
        </Container>
      </Section>
    )
  }
}
