import React from 'react'
import PropTypes from 'prop-types'

import { Section, Container, Heading, WpContent } from 'components'

const propTypes = {
  recipe: PropTypes.object
}

const defaultProps = {
  recipe: {}
}

const RecipeSection = (props) => (
  <Section>
    <Container column itemsCenter style={{ paddingTop: '17px', maxWidth: 810 }}>
      <Heading>Przepisy</Heading>
      <WpContent maxWidth={827} mTop={26} mBottom={70} html={props.recipe.content.rendered}/>
    </Container>
  </Section>
)

RecipeSection.propTypes = propTypes
RecipeSection.defaultProps = defaultProps

export default RecipeSection
