import React from 'react'
import styled from 'styled-components'

import { Section, Container, Image, Row, Col, Heading/*, Paragraph */ } from 'components'

const NewsSection = props => (
  <Section>
    <Container column itemsCenter style={{ padding: '57px 0' }}>
      <Heading style={{ textAlign: 'center' }}>Co nowego w gospodarstwie</Heading>
      {/* <Paragraph mTop={16}>Co tam się dzieje, lorem ipsum, te sprawy</Paragraph> */}
      {/* <Row mTop={60} style={{ maxWidth: 821 }}>
        {props.instagramPhotos.slice(0,3).map((item, i) => (
          <Col key={i} column justifyCenter itemsCenter small={12} medium={6} large={4} mTop={25}>
            <ImageWrapper>
              <Image src={item.images.low_resolution.url} />
              <SocialIcon className="fab fa-facebook-square" />
            </ImageWrapper>
          </Col>
        ))}
      </Row> */}
      <Row mTop={60} style={{ maxWidth: 821 }}>
        {props.instagramPhotos.slice(0,3).map((item, i) => (
          <Col key={i} column justifyCenter itemsCenter small={12} medium={6} large={4} mTop={25}>
            <ImageWrapper href={item.link}>
              <Image src={item.images.low_resolution.url} />
              <SocialIcon className="fab fa-instagram" />
            </ImageWrapper>
          </Col>
        ))}
      </Row>
    </Container>
  </Section>
)

export default NewsSection

const ImageWrapper = styled.a.attrs({ target: '_blank' })`
  position: relative;
  display: block;
`

const SocialIcon = styled.i`
  color: white;
  font-size: 24px;

  position: absolute;
  top: 8px;
  right: 8px;
`
