export const urls = {
  apiBase: 'https://www.majlert.pl/wordpress/index.php/wp-json/wp/v2',
  facebook: 'https://www.facebook.com/GospodarstwoRolneLudwikMajlert/',
  instagram: 'https://www.instagram.com/gospodarstwo/',
  youTube: 'https://www.youtube.com/channel/UCKfBJyOPu1R1t7nBSomjOHg',
  googleMaps: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCvmBgMP7p5ku9fzHj79b5EgirQeQ9qEug&callback=initMap'
}

export const postCategoriesToFetch = {
  glowna: ['aktualnosci', 'produkt', 'youtube'],
  produkty: ['produkt'],
  'pojedynczy-produkt': ['produkt', 'przepis'],
  'o-gospodarstwie': ['osoba', 'historia', 'rodzina'],
  aktualnosci: ['aktualnosci'],
  'aktualnosci-pojedynczy': ['aktualnosci'],
  inicjatywy: ['inicjatywy', 'youtube'],
  sklepik: ['sklepik', 'sklepikopis', 'sklepikaktualizacjacen']
}

export const endpoints = {
  categories: '/categories?per_page=50',
  pages: '/pages?orderby=menu_order&order=asc&_embed',
  posts: (categoryName, numberOfItems = 50) => `/posts?categories=${categoryName}&_embed&per_page=${numberOfItems}`,
  instagramFeed: 'https://api.instagram.com/v1/users/self/media/recent/?access_token=1764547786.66a8d5c.8a2ad361fb7244229e415836fffb384a'
}
