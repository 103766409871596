import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { getFeaturedMedia } from 'utils'
import { contentProvider } from 'decorators'
import { Section, HeroImage, LargeHeading, Container, WpContent, Loader } from 'components'

import CurrentShopItemsSection from './CurrentShopItemsSection'
import ShopDescription from './ShopDescription'

const PAGE_NAME = 'sklepik'

const propTypes = {
  page: PropTypes.object,
  sklepikPosts: PropTypes.array,
  sklepikopisPosts: PropTypes.array,
  sklepikaktualizacjacenPosts: PropTypes.array,
}

const Page = props => {
  const noSklepikPostsInLocalStorage = !(props.sklepikPosts.length > 0)
  const noSklepikopisPostsInLocalStorage = !(props.sklepikopisPosts.length > 0)

  return (
  <Fragment>
    <HeroImage src={getFeaturedMedia(props.page)} />

    <Section>
      <Container column itemsCenter>
        <LargeHeading mTop={31} >{props.page.title.rendered}</LargeHeading>

        <WpContent maxWidth={827} mTop={35} mBottom={10} html={props.page.content.rendered} />
      </Container>
    </Section>

    <Loader isLoading={noSklepikPostsInLocalStorage}>
      <CurrentShopItemsSection items={props.sklepikPosts} date={props.sklepikaktualizacjacenPosts[0]} />
    </Loader>

    <Loader isLoading={noSklepikopisPostsInLocalStorage}>
      <ShopDescription description={props.sklepikopisPosts}/>
    </Loader>

  </Fragment>
)}

Page.propTypes = propTypes

export default contentProvider(PAGE_NAME)(Page)
