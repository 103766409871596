import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import CSSTransition from 'react-transition-group/CSSTransition'

import { media } from 'styles'

import CloseIcon from './CloseIcon'

const propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  transitionDuration: PropTypes.number,
  transitionProps: PropTypes.shape({}),
  backgroundProps: PropTypes.shape({}),
  children: PropTypes.node,
}

const defaultProps = {
  show: false,
  onClose: () => {},
  transitionDuration: 200,
  transitionProps: null,
  backgroundProps: null,
  children: null,
}

const bodyClasses = document.body.classList

let modalRoot = document.getElementById('modal-root')

if (!modalRoot) {
  modalRoot = document.createElement('div')
  modalRoot.setAttribute('id', 'modal-root')
  document.body.appendChild(modalRoot)
}

const Modal = ({ show, onClose, children, transitionDuration, transitionProps, backgroundProps, ...props }) => {
  const handleKeyDown = ({ key }) => key === 'Escape' && onClose()

  const handleBackgroundClick = ({ target, currentTarget }) => target === currentTarget && onClose()

  const manageKeyDownListeners = () => {
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }

  useEffect(manageKeyDownListeners)

  const toggleGlobalScroll = () => (show ? bodyClasses.add('no-scroll') : bodyClasses.remove('no-scroll'))

  useEffect(toggleGlobalScroll, [show])

  return createPortal(
    <CSSTransition
      in={show}
      mountOnEnter
      unmountOnExit
      timeout={transitionDuration}
      classNames="animation"
      {...transitionProps}
    >
      <Background transitionDuration={transitionDuration} onClick={handleBackgroundClick} {...backgroundProps}>
        <Content transitionDuration={transitionDuration} {...props}>
          <CloseButton onClick={onClose} />
          {children}
        </Content>
      </Background>
    </CSSTransition>,
    modalRoot
  )
}

Modal.propTypes = propTypes
Modal.defaultProps = defaultProps

export default Modal

// prettier-ignore
const Content = styled.div`
  width: 100%;
  padding: 40px 16px;

  position: relative;

  background: white;

  ${media.largeUp`
    max-width: 900px;
    height: 90vh;
    max-height: 623px;

    margin-top: 5vh;
    padding: 48px 52px;

    border-radius: 8px;
  `}

  ${media.mediumDown`height: 100%;`}
`

const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: rgba(61, 64, 76, 0.5);
  z-index: 999;

  ${media.mediumDown`overflow: auto;`}

  ${({ transitionDuration }) => css`
    &.animation {
      &-enter {
        opacity: 0;

        ${Content} {
          transform: translateY(20px);
        }

        &-active {
          opacity: 1;
          transition: opacity ${transitionDuration}ms ease-out;

          ${Content} {
            transform: translateY(0);
            transition: transform ${transitionDuration}ms ease-out;
          }
        }
      }

      &-exit {
        opacity: 1;

        &-active {
          opacity: 0;
          transition: opacity ${transitionDuration}ms ease-out;
        }
      }
    }
  `}
`

const CloseButton = styled(CloseIcon)`
  position: absolute;
  top: 16px;
  right: 16px;

  width: 24px;
  height: 24px;
  cursor: pointer;

  transition: 400ms;

  &:hover {
    transform: rotate(90deg);
  }
`
