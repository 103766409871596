export { default as AboutPage } from './o-gospodarstwie/Page'
export { default as ContactPage } from './kontakt/Page'
export { default as HomePage } from './glowna/Page'
export { default as ProductsPage } from './produkty/Page'
export { default as NotFoundPage } from './not-found/Page'
export { default as ShopPage } from './sklepik/Page'
export { default as NewsPage } from './aktualnosci/Page'
export { default as InitiativePage } from './inicjatywy/Page'
export { default as SingleProductPage } from './pojedynczy-produkt/Page'
export { default as SingleNewsPage } from './aktualnosci-pojedynczy/Page'
