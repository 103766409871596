import React, { useState } from 'react'
import styled from 'styled-components'

import { Div, Paragraph, Heading, NewsImage, Modal, YouTubePlayer } from 'components'
import { colors } from 'styles'

const YouTubeNews = props => {
  const [isVisible, setIsVisible] = useState(false)

  const handleModalToggle = () => setIsVisible(!isVisible)

  return (
    <Div column justifyCenter itemsCenter style={{ maxWidth: 339, width: '100%' }}>
      <NewsImageWrapper onClick={handleModalToggle}>
        <NewsImage src={props.img} />
        <SocialIcon className="fab fa-youtube" />
      </NewsImageWrapper>
      <Div mTop={18} column style={{ width: '100%' }}>
        <YouTubeNewsTitle center>{props.title}</YouTubeNewsTitle>
        <Paragraph center>{props.date}</Paragraph>
        <YouTubeNewsWatch onClick={handleModalToggle}>Oglądaj</YouTubeNewsWatch>
      </Div>

      <Modal show={isVisible} onClose={handleModalToggle}>
        <Div column itemsCenter listTop={24} height="100%">
          <Heading center>{props.title}</Heading>

          <YouTubePlayer url={props.url} />
        </Div>
      </Modal>
    </Div>
)}

export default YouTubeNews

const YouTubeNewsTitle = styled(Paragraph)`
  margin: 0;
  font-weight: bold;
  margin: 0;
`

const NewsImageWrapper = styled.button`
  position: relative;
  display: block;
  width: 100%;
  outline: none;
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
`

const SocialIcon = styled.i`
  color: white;
  font-size: 48px;

  position: absolute;
  top: 88px;
  left: 145px;
`

const YouTubeNewsWatch = styled.button`
  margin: 0;
  margin-top: 16px;
  color: ${colors.green};

  border: none;
  background: transparent;

  outline: none;
  cursor: pointer;

  ::after {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;

    border: 3px solid ${colors.green};
    border-radius: 2px;
    border-top: none;
    border-right: none;

    transform: rotate(-135deg);
`
