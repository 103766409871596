import React from 'react'
import PropTypes from 'prop-types'

import { Spinner } from 'components'

const propTypes = {
  isLoading: PropTypes.any,
  children: PropTypes.node
}

const defaultProps = {
  isLoading: false,
  children: null
}

const Loader = ({ isLoading, children, ...props }) =>
  (isLoading ? <Spinner style={{ margin: '150px auto' }} {...props} /> : children)

Loader.propTypes = propTypes
Loader.defaultProps = defaultProps

export default Loader
