import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _sortBy from 'lodash.sortby'

import { getFeaturedMedia } from 'utils'
import { media, colors } from 'styles'
import { Section, Container, Div, Heading, WpContent, ResponsiveImage } from 'components'

const propTypes = {
  items: PropTypes.array,
  description: PropTypes.object
}

const defaultProps = {
  description: { title: {}, content: {} }
}

const FamilySection = props => (
  <Section>
    <Container column itemsCenter listBottom={24} style={{ maxWidth: 827 }}>
      <Heading>{props.description.title.rendered}</Heading>

      <WpContent mTop={0} mBottom={70} html={props.description.content.rendered} />

      <ResponsiveImage src={getFeaturedMedia(props.description)} />

      <Div maxWidth={780} mTop={56} column>
        {_sortBy(props.items, 'date').map(item => <Item key={item.id} {...item} />)}
      </Div>
    </Container>
  </Section>
)

FamilySection.propTypes = propTypes
FamilySection.defaultProps = defaultProps

export default FamilySection

const Item = item => (
  <StyledItem>
    <ResponsiveImage src={getFeaturedMedia(item)} maxWidth={387} />

    <Div flex={1} column padding="45px 0 24px">
      <Name>{item.title.rendered}</Name>
      <Role>({item.acf.rola})</Role>
      <WpContent small html={item.content.rendered} style={{ marginTop: 10 }} />
    </Div>
  </StyledItem>
)

const StyledItem = Div.extend`
  flex-direction: column;
  align-items: center;

  ${media.mediumUp`
    &:nth-child(odd) {
      flex-direction: row;
      > *:not(:first-child) {
        margin-left: 24px;
      }
    }

    &:nth-child(even) {
      flex-direction: row-reverse;
      text-align: right;
      > *:not(:first-child) {
        margin-right: 24px;
      }
    }
  `}
`

const Name = styled.h3`
  margin: 0;
  font-size: 17px;
  line-height: 1.47;
  font-weight: bold;
  color: ${colors.green};
`

const Role = styled.h4`
  margin: 8px 0 0;
  font-size: 17px;
  line-height: 1.47;
  font-weight: normal;
`
