import React from 'react'
import styled, { keyframes } from 'styled-components'

export default props => (
  <Spinner {...props}>
    <Dot />
    <Dot />
    <Dot />
  </Spinner>
)

const bounce = keyframes`
  0%, 80%, 100% { transform: scale(0); }
  40% { transform: scale(1); }
`

const Spinner = styled.div`
  width: 70px;
  margin: auto;
  text-align: center;
`

const Dot = styled.div`
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: #333;
  border-radius: 100%;
  animation: ${bounce} 1.4s infinite ease-in-out both;

  &:nth-child(1) {
    animation-delay: -0.32s;
  }

  &:nth-child(2) {
    animation-delay: -0.16s;
  }
`
