import axios from 'axios'
// import _isEmpty from 'lodash.isempty'

import { endpoints } from 'config'
import { getError } from 'utils'
import { store, getState, types } from 'store'

const actions = {
  appReady: () => ({ type: types.APP_READY }),
  // Fetches all post categories that will be used to later fetch particular post groups
  fetchCategories: (resolve, reject) => () => {
    // if (!_isEmpty(getState().categories) || getState().requests.fetchCategories) return resolve

    dispatch().hideError('fetchCategories')
    dispatch().startRequest('fetchCategories')

    axios
      .get(endpoints.categories)
      .then(response => {
        dispatch({ type: types.FETCH_CATEGORIES, data: response.data })
        dispatch().stopRequest('fetchCategories')
        resolve()
      })
      .catch(error => {
        dispatch().showError({ id: 'fetchCategories', message: getError(error) })
        dispatch().stopRequest('fetchCategories')
        reject(error)
      })
  },
  // Fetches all pages from Wordpress
  fetchPages: (resolve, reject) => () => {
    // if (!_isEmpty(getState().pages) || getState().requests.fetchPages) return resolve

    dispatch().hideError('fetchPages')
    dispatch().startRequest('fetchPages')

    axios
      .get(endpoints.pages)
      .then(response => {
        dispatch({ type: types.FETCH_PAGES, data: response.data })
        dispatch().stopRequest('fetchPages')
        resolve()
      })
      .catch(error => {
        dispatch().showError({ id: 'fetchPages', message: getError(error) })
        dispatch().stopRequest('fetchPages')
        reject(error)
      })
  },
  // Fetches posts of given category
  fetchPosts: (categoryName, resolve, reject) => () => {
    if (!getState().categories[categoryName]) return reject
    // if (getState().posts[categoryName] || getState().requests[`${categoryName}PostsFetching`]) return resolve

    dispatch().hideError(`${categoryName}PostsFetching`)
    dispatch().startRequest(`${categoryName}PostsFetching`)

    axios
      .get(endpoints.posts(getState().categories[categoryName], 100))
      .then(response => {
        dispatch({ type: types.FETCH_POSTS, categoryName, data: response.data })
        dispatch().stopRequest(`${categoryName}PostsFetching`)
        resolve()
      })
      .catch(error => {
        dispatch().showError({ id: `${categoryName}PostsFetching`, message: getError(error) })
        dispatch().stopRequest(`${categoryName}PostsFetching`)
        reject(error)
      })
  },
  // Fetches latests Instagram posts
  fetchInstagram: (resolve, reject) => () => {
    axios
      .get(endpoints.instagramFeed)
      .then(response => {
        dispatch({ type: 'FETCH_INSTAGRAM', data: response.data ? response.data.data : [] })
        resolve()
      })
  },
  // Requests
  startRequest: name => () => !getState().requests[name] && dispatch({ type: types.START_REQUEST, name }),
  stopRequest: name => () => getState().requests[name] && dispatch({ type: types.STOP_REQUEST, name }),
  // Errors
  showError: error => ({ type: types.SHOW_ERROR, error }),
  hideError: id => () => getState().errors.find(error => error.id === id) && dispatch({ type: types.HIDE_ERROR, id })
}

const dispatcher = Object.keys(actions).reduce(
  (all, action) => ({
    ...all,
    [action]: (...payload) =>
      new Promise((resolve, reject) => store.dispatch(actions[action](...payload, resolve, reject)))
  }),
  {}
)

export const dispatch = action => {
  if (action) store.dispatch(action)
  else return dispatcher
}
