import React from 'react'
import PropTypes from 'prop-types'

import { Section, Container, Heading, WpContent } from 'components'
import { colors } from 'styles'

const propTypes = {
  news: PropTypes.object
}

const defaultProps = {
  news: {}
}

const DescriptionSection = (props) => (
  <Section>
    <Container column itemsCenter style={{ padding: '57px 0', maxWidth: 810 }}>
      <Heading style={{ color: `${colors.green}` }}>{props.news.title.rendered}</Heading>
      <WpContent maxWidth={827} mTop={26} mBottom={70} html={props.news.content.rendered}/>
    </Container>
  </Section>
)

DescriptionSection.propTypes = propTypes
DescriptionSection.defaultProps = defaultProps

export default DescriptionSection
