import React from 'react'
import obiad1 from 'assets/images/obiad1.jpg'
import obiad2 from 'assets/images/obiad2.jpg'
import obiad3 from 'assets/images/obiad3.jpg'

import { Section, Container, Div, Heading, Paragraph, Row, Col, NewsImage, Traktor } from 'components'

const ObiadyNaPoluSection = props => (
  <Section>
    <Traktor style={{ marginTop: '-50px' }} />
    <Container column itemsCenter style={{ marginTop: 50, marginBottom: 50 }}>
      <Div column itemsCenter maxWidth={827}>
        <Heading>Obiady na polu</Heading>
        <Paragraph mTop={34} center>
          Obiady na polu to cotygodniowe spotkania przy wspólnym stole, na którym serwowane są potrawy z naszych upraw.
          To okazja do poznania historii gospodarstwa, rozmowy, wymiany doświadczeń i jedyna szansa na zjedzenie obiadu
          wśród łanów szparagowych krzaków czy kwitnącej cukinii.
        </Paragraph>
        <Paragraph mTop={34} center>
          Kiedy? Obiady odbywają się w czerwcowe i wrześniowe weekendy. Rozpoczynamy je wspólnym spacerem po polu.
        </Paragraph>
        <Paragraph mTop={34} center>
          Kto? Każdy może wziąć udział w obiedzie – samemu albo w grupie. Mamy 24 miejsca przy stole.
        </Paragraph>
        <Paragraph mTop={34} center>
          Co jemy? W menu znajdują się głównie warzywa z naszego pola. Nie ma mięsa, ale pojawia się nabiał i gluten.
          Obiad składa się zazwyczaj z pięciu dań i kończy deserem.
        </Paragraph>
        <Paragraph mTop={34} center>
          Co trzeba zrobić? Za organizację obiadów odpowiada Stefan. Pytania i rezerwacje prosimy kierować na adres{' '}
          <a href="mailto:obiadnapolu@majlert.pl" style={{ fontWeight: 600 }}>
            obiadnapolu@majlert.pl
          </a>{' '}
          lub przy użyciu messengera{' '}
          <a href="https://www.facebook.com/Obiadnapolu" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 600 }}>
            www.facebook.com/Obiadnapolu
          </a>
        </Paragraph>
      </Div>
      <Row mTop={6} width="100%" justifyCenter>
        <Col column justifyStart itemsCenter small={12} medium={6} large={4} mTop={54}>
          <NewsImage src={obiad1} style={{ height: '250px' }} />
        </Col>
        <Col column justifyStart itemsCenter small={12} medium={6} large={4} mTop={54}>
          <NewsImage src={obiad2} style={{ height: '250px' }} />
        </Col>
        <Col column justifyStart itemsCenter small={12} medium={6} large={4} mTop={54}>
          <NewsImage src={obiad3} style={{ height: '250px' }} />
        </Col>
      </Row>
    </Container>
  </Section>
)

export default ObiadyNaPoluSection
