import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { getFeaturedMedia } from 'utils'
import { contentProvider } from 'decorators'
import { HeroImage, Loader } from 'components'

import DescriptionSection from './DescriptionSection'

const PAGE_NAME = 'aktualnosci-pojedynczy'

const propTypes = {
  match: PropTypes.object,
  aktualnosciPosts: PropTypes.array,
}


const Page = props => {
  // get clicked news
  const news = props.aktualnosciPosts.find(aktualnosc => aktualnosc.id === parseInt(props.match.params.id, 10))

  if (!news) return <Loader isLoading />

  return (
    <Fragment>
      <HeroImage src={getFeaturedMedia(news)} />

      <DescriptionSection news={news} />

    </Fragment>
  )
}

Page.propTypes = propTypes

export default contentProvider(PAGE_NAME)(Page)
