import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { colors, media } from 'styles'
import { Div } from 'components'

const letters = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','R','S','T','U','W','Z']

const LetterSelector = props => {
  const availableProducts = props.products
    .map(product => product.title.rendered[0])
    .filter((letter, index, array) => array.indexOf(letter) === index)

  return (
    <LettersWrapper>
      {letters.map(letter => {
        const isUnavailable = !availableProducts.includes(letter)
        return (
        <Button key={letter} disabled={isUnavailable} active={props.chosenLetter === letter} onClick={props.onClick(letter)}>
          {letter}
          {isUnavailable && <Tooltip>Na literę <span style={{ color: 'black' }}>{letter}</span> jeszcze nic u nas nie wyrosło</Tooltip>}
        </Button>
      )})}
    </LettersWrapper>
  )
}

LetterSelector.propTypes = {
  chosenLetter: PropTypes.string,
  onClick: PropTypes.func,
  products: PropTypes.array,
}

export default LetterSelector

const Tooltip = styled.span`
  position: absolute;
  padding: 12px;
  background: #bfbfbf;
  color: #555;
  border-radius: 8px;
  opacity: 0;
  bottom: 120%;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  font-size: 16px;
  white-space: unset;
  line-height: 1;
  transition: opacity 200ms;
`

const Button = styled.button`
  font-family: Calibri, sans-serif;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  width: 38px;
  height: 35px;
  font-size: 25px;
  font-weight: bold;
  line-height: 38px;
  text-align: center;
  color: inherit;
  transition: 0.3s;

  ${'' /* ${media.largeUp`position: relative;`} */}

  ${props => props.active && `
    background: ${colors.lightGray};
    color: black;
  `}

  &:disabled {
    cursor: default;
    color: #ddd;

    &:hover ${Tooltip} { opacity: 1; }
  }

  &:enabled:hover {
    color: black;
  }

  &:not(:last-child) {
    border-right: solid 2px #bfbfbf;
  }
`

const LettersWrapper = styled(Div)`
  flex-wrap: wrap;
  justify-content: center;
  position: relative;

  ${media.largeUp`flex-wrap: nowrap`}
`
