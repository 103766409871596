import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { getFeaturedMedia } from 'utils'
import { contentProvider } from 'decorators'
import { Section, Container, LargeHeading, HeroImage, WpContent, Loader } from 'components'

import ProductsSection from './ProductsSection'
import GuestProductsSection from './GuestProductsSection'

const PAGE_NAME = 'produkty'

const propTypes = {
  page: PropTypes.object,
  produktPosts: PropTypes.array,
}

const Page = props => {
  const noProduktPostsInLocalStorage = !(props.produktPosts.length > 0)

  return (
  <Fragment>
    <HeroImage src={getFeaturedMedia(props.page)} />

    <Section>
      <Container narrow column itemsCenter>
        <LargeHeading mTop={31} >{props.page.title.rendered}</LargeHeading>

        <WpContent mTop={46} html={props.page.content.rendered} />
      </Container>
    </Section>

    <Loader isLoading={noProduktPostsInLocalStorage}>
      <ProductsSection products={props.produktPosts.filter(product => !product.acf.goscinny)} />
      <GuestProductsSection products={props.produktPosts.filter(product => product.acf.goscinny)} />
    </Loader>
  </Fragment>
)}

Page.propTypes = propTypes

export default contentProvider(PAGE_NAME)(Page)
