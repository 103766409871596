import React from 'react'
import PropTypes from 'prop-types'

import { getFeaturedMedia } from 'utils'
import { Section, Container, Row, Col, Heading, /* Paragraph, */ Product } from 'components'
import { colors } from 'styles'

const propTypes = {
  products: PropTypes.array
}

const defaultProps = {
  products: {}
}

const RecomendedProductSection = (props) => {
  return (
  <Section style={{ background: `${colors.lightGray}` }}>
    <Container column itemsCenter style={{ padding: "57px 0" }}>
      <Heading>Na polu obrodziło</Heading>
      {/* <Paragraph mTop={16}>W tym tygodniu polecamy państwu trochę tekstu</Paragraph> */}
      <Row mTop={60} justifyCenter style={{ width: '100%'}}>
        {props.products.map(product => (
          <Col key={product.id} column justifyCenter itemsCenter small={12} medium={6} large={2}>
            <Product
              borderColor='white'
              id={product.id}
              img={getFeaturedMedia(product)}
              name={product.title.rendered}
              text={product.acf.nazwa_lacinska}
            />
            <p>{product.acf.cena}</p>
          </Col>
        ))}
      </Row>
      {/* Button zakomentowany na prośbę klientki */}
      {/* <Link to={`/produkty`} style={{ position: 'relative' }}>
        <ButtonSeeMoreProducts>zobacz więcej produktów </ButtonSeeMoreProducts>
      </Link> */}
    </Container>
  </Section>
)}

RecomendedProductSection.propTypes = propTypes
RecomendedProductSection.defaultProps = defaultProps

export default RecomendedProductSection

// const ButtonSeeMoreProducts = styled.button`
//   font-family: Calibri, sans-serif;
//   font-size: 21px;
//   background: ${colors.green};
//   color: white;
//   padding: 15px 43px;
//   padding-right: 52px;
//
//   outline: none;
//   cursor: pointer;
//
//   ::after {
//     content: '';
//     display: block;
//     position: absolute;
//     top: 20px;
//     right: 33px;
//     width: 13px;
//     height: 13px;
//
//     border: 3px solid #ffffff;
//     border-radius: 2px;
//     border-top: none;
//     border-right: none;
//
//     transform: rotate(-135deg);
//   }
// `
