import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { getFeaturedMedia } from 'utils'
import { contentProvider } from 'decorators'
import { Section, Container, LargeHeading, HeroImage, Loader, Traktor } from 'components'
import KalendariumSection from './KalendariumSection'

const PAGE_NAME = 'aktualnosci'

const propTypes = {
  page: PropTypes.object,
  aktualnosciPosts: PropTypes.array,
}

const Page = props => {
  const noDataInLocalStorage = !(props.aktualnosciPosts.length > 0)

  return (
  <Fragment>
    <HeroImage src={getFeaturedMedia(props.page)} />

    <Section>
      <Traktor style={{ marginTop: '-50px' }} />

      <Container column itemsCenter>
        <LargeHeading mTop={31} >{props.page.title.rendered}</LargeHeading>

        <Loader isLoading={noDataInLocalStorage}>
          <KalendariumSection news={props.aktualnosciPosts}/>
        </Loader>
      </Container>
    </Section>
  </Fragment>
)}

Page.propTypes = propTypes

export default contentProvider(PAGE_NAME)(Page)
