import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { Div, Paragraph, NewsImage } from 'components'
import { colors } from 'styles'
import { getDateAktualnosci } from 'utils'

const News = props => {
  let data = getDateAktualnosci(props.data)

  return (
    <Div column justifyCenter itemsCenter style={{ maxWidth: 339, width: '100%' }}>
      <NewsImage src={props.img} />
      <Div mTop={18} style={{ width: '100%' }}>
        <NewsDate itemsCenter mRight={9}>
          <p style={{ margin: 0, fontSize: '20px' }}>{data.day}.{data.month}</p>
          <p style={{ margin: 0, fontSize: '14px' }}>{data.year}</p>
        </NewsDate>

        <Div column>
          <NewsTitle dangerouslySetInnerHTML={{ __html: props.title }} />
          <NewsText dangerouslySetInnerHTML={{ __html: props.text }} />
          <NewsReadMore to={`/aktualnosci/${props.id}`}>{props.more}</NewsReadMore>
        </Div>
      </Div>
    </Div>
)}

export default News


const NewsDate = styled(Div)`
  flex-direction: column;
  justify-content: center;
  background: ${colors.green};
  min-width: 59px;
  height: 81px;
  color: white;
  text-align: center;
`

const NewsTitle = styled(Paragraph)`
  margin: 0;
  font-weight: bold;
  margin: 0;
`

const NewsText = styled(Paragraph)`
  margin: 0;
  margin-top: 13px;
  font-size: 14px;
  max-height: 105px;

  text-overflow: ellipsis;
  overflow: hidden;

  > * {
    margin: 0;
  }
`

const NewsReadMore = styled(Link)`
  margin: 0;
  margin-top: 16px;
  color: ${colors.green};
  position: relative;

  ::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 3px;
    left: 92px;
    width: 8px;
    height: 8px;

    border: 3px solid ${colors.green};
    border-radius: 2px;
    border-top: none;
    border-right: none;

    transform: rotate(-135deg);
`
