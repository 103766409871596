import { Component } from 'react'
import PropTypes from 'prop-types'
import _isEqual from 'lodash.isequal'

import { queries } from 'styles'

const getQueryValues = queries =>
  queries.reduce(
    (all, query) => ({ ...all, [query.name]: window.matchMedia(`only screen and ${query.value}`).matches }),
    {}
  )

export default class MediaQueryProvider extends Component {
  static childContextTypes = { media: PropTypes.object }

  state = { media: getQueryValues(queries) }

  getChildContext = () => ({ media: this.state.media })

  componentDidMount () {
    queries.forEach(query => {
      window.matchMedia(`only screen and ${query.value}`).addListener(() => {
        if (!_isEqual(this.state.media, getQueryValues(queries))) this.setState({ media: getQueryValues(queries) })
      })
    })
  }

  render = () => this.props.children
}
