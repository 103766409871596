import { css, injectGlobal } from 'styled-components'

import HaloHandletter from 'assets/fonts/HaloHandletter.otf'
import BradleyHandITC from 'assets/fonts/BradleyHandITC.otf'
import HavanaRegular from 'assets/fonts/HavanaRegular.otf'

export const queries = [
  { name: 'smallOnly', value: '(max-width: 639px)' },
  { name: 'mediumUp', value: '(min-width: 640px)' },
  { name: 'mediumDown', value: '(max-width: 1023px)' },
  { name: 'largeUp', value: '(min-width: 1024px)' }
]

// Iterate through all the sizes and create a media template for each one
export const media = queries.reduce((all, query) => ({
  ...all,
  [query.name]: (...args) => css`@media only screen and ${query.value} { ${css(...args)}; }`
}), {})

export const colors = {
  green: '#77a741',
  nearlyWhite: '#fafafa',
  lightGray: '#ebebec',
  darkGray: '#797d80'
}

injectGlobal`
  @font-face {
    font-family: HaloHandletter;
    src: url(${HaloHandletter}) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: BradleyHandITC;
    src: url(${BradleyHandITC}) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: HavanaRegular;
    src: url(${HavanaRegular}) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  body {
    color: ${colors.darkGray};
    font: 16px Calibri, sans-serif;

    box-sizing: border-box;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  * {
    box-sizing: inherit;
    user-drag: none;
  }

  a {
    color: inherit;
    text-decoration: none;
    user-select: none;
    cursor: pointer;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-x: hidden;
  }

  .no-scroll {
    ${media.mediumDown`overflow: hidden;`}
  }
`
