import React from 'react'
import PropTypes from 'prop-types'

import { Section, Container, Heading, Paragraph, WpContent } from 'components'
import { colors } from 'styles'

const propTypes = {
  product: PropTypes.object
}

const defaultProps = {
  product: {}
}

const DescriptionSection = (props) => (
  <Section>
    <Container column itemsCenter style={{ paddingTop: '27px', maxWidth: 810 }}>
      <Heading style={{ color: `${colors.green}` }}>{props.product.title.rendered}</Heading>
      <Paragraph mTop={18} style={{ fontSize: 21 }}>{props.product.acf.nazwa_lacinska}</Paragraph>
      <WpContent maxWidth={827} mTop={26} html={props.product.content.rendered}/>
    </Container>
  </Section>
)

DescriptionSection.propTypes = propTypes
DescriptionSection.defaultProps = defaultProps

export default DescriptionSection
