import React from 'react'

import { Section, Container, Heading } from 'components'

export default () => (
  <Section>
    <Container>
      <Heading center width="100%">404</Heading>
    </Container>
  </Section>
)
