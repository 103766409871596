import styled from 'styled-components'

import baseStyles from './TypographyBase'

export default styled.h3`
  ${baseStyles}

	font-family: Calibri, sans-serif;
	font-size: 24px;
`
