import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Section, Container, Heading, WpContent, Div } from 'components'

const propTypes = {
  description: PropTypes.array
}

const ShopDescription = props => (
  <Section>
    <Container column itemsCenter>
      <Heading mTop={31} >{props.description[0] && props.description[0].title.rendered}</Heading>

      <WpContent maxWidth={827} mTop={21} mBottom={26} html={props.description[0] && props.description[0].content.rendered} />

      <ImageWrapper mBottom={64}>
        <Image src='https://www.majlert.pl/wordpress/wp-content/uploads/2018/01/sklepik_1.jpg' alt="o sklepiku" />
        <Image src='https://www.majlert.pl/wordpress/wp-content/uploads/2018/01/sklepik_2.jpg' alt="o sklepiku" />
      </ImageWrapper>

    </Container>
  </Section>
)

ShopDescription.propTypes = propTypes

export default ShopDescription

const ImageWrapper = styled(Div)`
  width: 65%;
  flex-wrap: wrap;
  justify-content: space-evenly;
`

const Image = styled.img`
  display: inline-block;
  width: 314px;
  height: 314px;
  margin-top: 12px;
`
