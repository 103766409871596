import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { getFeaturedMedia } from 'utils'
import { contentProvider } from 'decorators'
import { HeroImage, Loader } from 'components'

import DescriptionSection from './DescriptionSection'
import RecipeSection from './RecipeSection'

const PAGE_NAME = 'pojedynczy-produkt'

const propTypes = {
  match: PropTypes.object,
  produktPosts: PropTypes.array,
  przepisPosts: PropTypes.array
}

const Page = props => {
  // get clicked product
  const product = props.produktPosts.find(produkt => produkt.id === parseInt(props.match.params.id, 10))

  // get clicked product recipe
  const recipe = product && props.przepisPosts.find(przepis => przepis.title.rendered === product.title.rendered)

  if (!product) return <Loader isLoading />

  return (
    <Fragment>
      <HeroImage src={getFeaturedMedia(product)} />
      <DescriptionSection product={product}/>
      {recipe && <RecipeSection recipe={recipe}/>}
    </Fragment>
  )
}

Page.propTypes = propTypes

export default contentProvider(PAGE_NAME)(Page)
