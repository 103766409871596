import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { getFeaturedMedia } from 'utils'
import { contentProvider } from 'decorators'
import { Section, Container, LargeHeading, HeroImage, WpContent, Loader } from 'components'

import TimelineSection from './TimelineSection'
import FamilySection from './FamilySection'

const PAGE_NAME = 'o-gospodarstwie'

const propTypes = {
  page: PropTypes.object,
  historiaPosts: PropTypes.array,
  osobaPosts: PropTypes.array,
  rodzinaPosts: PropTypes.array,
}

const Page = props => {
  const noHistoriaPostsInLocalStorage = !(props.historiaPosts.length > 0)
  const noOsobaPostsInLocalStorage = !(props.osobaPosts.length > 0)
  const noRodzinaPostsInLocalStorage = !(props.rodzinaPosts.length > 0)

  return (
  <Fragment>
    <HeroImage src={getFeaturedMedia(props.page)} />

    <Section>
      <Container column itemsCenter>
        <LargeHeading mTop={31} >{props.page.title.rendered}</LargeHeading>

        <WpContent maxWidth={827} mTop={26} mBottom={10} html={props.page.content.rendered} />
      </Container>
    </Section>

    <Loader isLoading={noHistoriaPostsInLocalStorage}>
      <TimelineSection items={props.historiaPosts} />
    </Loader>

    <Loader isLoading={noOsobaPostsInLocalStorage || noRodzinaPostsInLocalStorage}>
      <FamilySection items={props.osobaPosts} description={props.rodzinaPosts[0]} />
    </Loader>
  </Fragment>
)}

Page.propTypes = propTypes

export default contentProvider(PAGE_NAME)(Page)
