import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { Paragraph, ProductImage } from 'components'
import { colors } from 'styles'

const Product = props => (
  <Wrapper to={`/produkty/${props.id}`}>
    <ProductImage src={props.img} borderColor={props.borderColor}/>
    <ProductName mTop={20}>{props.name}</ProductName>
    <ProductText>{props.text}</ProductText>
  </Wrapper>
)

export default Product

const Wrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  &:hover > div:first-child {
    transform: scale(1.05);
  }
`

const ProductName = styled(Paragraph)`
  color: ${colors.green};
  font-weight: bold;
  font-style: normal;
  text-align: center;
`

const ProductText = styled(Paragraph)`
  text-align: center;
`
