import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { media, colors } from 'styles'
import { preloadImage, withUnit } from 'utils'

export class Image extends Component {
  static propTypes = {
    src: PropTypes.string,
    style: PropTypes.object
  }

  state = {
    ready: false
  }

  componentDidMount () {
    preloadImage(this.props.src).then(ready => this.setState({ ready }))
  }

  componentWillReceiveProps (nextProps) {
    if (!this.state.ready && !this.props.src && nextProps.src) {
      preloadImage(nextProps.src).then(ready => this.setState({ ready }))
    }
  }

  render = () => {
    const { src, style, ...props } = this.props

    return (
      <BaseImage
        {...props}
        ready={this.state.ready}
        style={{ ...style, backgroundImage: `url(${src})` }}
      />
    )
  }
}

const BaseImage = styled.div`
  position: relative;

  width: 250px;
  height: 250px;

  background-color: gray;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;

  overflow: hidden;

  &::after {
    content: '';

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background: gray;
    opacity: ${props => (props.ready ? 0 : 1)};
    transition: opacity 0.5s;
  }
`

export const ResponsiveImage = styled.img`
  display: block;
  width: 100%;
  ${props => props.maxWidth && css`max-width: ${withUnit(props.maxWidth)};`}
`

export const ProductImage = styled(Image)`
  width: 168px;
  height: 168px;
  border: 6px solid ${props => props.borderColor};
  border-radius: 50%;
  transition: transform 0.2s;
`

ProductImage.defaultProps = {
  borderColor: colors.lightGray
}

export const HeroImage = styled(Image)`
  width: 100%;
  height: 240px;
  background-position: center;

  ${media.mediumUp`height: 320px;`}
  ${media.largeUp`height: 520px;`}
`

export const NewsImage = styled(Image)`
  width: 100%;
  height: 225px;
`
