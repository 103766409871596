import { combineReducers } from 'redux'

// 2. Tutaj zamiast wrzucać pusty obiekt do categories, pages i posts, sprawdzimy w Local Storage
// czy istnieją dane dla tych kluczy. Jeżeli tak, sparsujemy je do JSONa i wrzucimy do obiektu poniżej
const getRetrievedCategories = localStorage.getItem('categories')
const categoriesJSON = JSON.parse(getRetrievedCategories)

const getRetrievedPages = localStorage.getItem('pages')
const pagesJSON = JSON.parse(getRetrievedPages)

const getRetrievedPosts = localStorage.getItem('posts')
const postsJSON = JSON.parse(getRetrievedPosts)

const initialState = {
  categories: categoriesJSON || {},
  pages: pagesJSON || {},
  posts: postsJSON || {},
  requests: {},
  errors: [],
  instagram: [],
  appReady: false
}

const reducers = {
  categories: {
    FETCH_CATEGORIES: (state, action) => {
      const categoriesData = action.data.reduce((all, category) => ({ ...all, [category.slug]: category.id }), {})
      // 1. Tutaj oprócz zapisania response do stora, zapiszmy jego zestringowaną wartość do Local Storage
      // Wygooglaj sobie jak zapisaywać JSONy do Local Storage
      localStorage.setItem('categories', JSON.stringify(categoriesData))
      return categoriesData
    }
  },
  pages: {
    FETCH_PAGES: (state, action) => {
      const pagesData = action.data.reduce((all, page) => ({ ...all, [page.slug]: page }), {})

      localStorage.setItem('pages', JSON.stringify(pagesData))
      return pagesData
    }
  },
  posts: {
    FETCH_POSTS: (state, action) => {
      const postsData = {
        ...state,
        [action.categoryName]: action.data
      }

      localStorage.setItem('posts', JSON.stringify(postsData))

      return postsData
    }
  },
  requests: {
    START_REQUEST: (state, action) => ({ ...state, [action.name]: true }),
    STOP_REQUEST: (state, action) => ({ ...state, [action.name]: false })
  },
  errors: {
    SHOW_ERROR: (state, action) => [...state.filter(error => error.id !== action.error.id), action.error],
    HIDE_ERROR: (state, action) => state.filter(error => error.id !== action.id)
  },
  instagram: {
    FETCH_INSTAGRAM: (state, action) => action.data
  },
  appReady: {
    APP_READY: () => true
  }
}

const createReducer = (name, initialState) => (state = initialState, action) =>
  (reducers[name][action.type] ? reducers[name][action.type](state, action) : state)

const uncombinedReducers = {
  ...Object.keys(reducers).reduce(
    (all, name) => ({
      ...all,
      [name]: createReducer(name, initialState[name])
    }),
    {}
  )
}

export default combineReducers(uncombinedReducers)
