import { css } from 'styled-components'

import { colors } from 'styles'
import { Div } from 'components'

export default Div.withComponent('section').extend`
  padding: 24px 0;

  ${props => !props.white && css`
    &:nth-child(odd) {
      background: ${colors.lightGray};
      border-top: 10px solid ${colors.nearlyWhite};

      &:not(:last-child) {
        border-bottom: 10px solid ${colors.nearlyWhite};
      }
    }
  `}
`
