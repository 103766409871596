import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withRouter, Link as StaticLink, NavLink } from 'react-router-dom'

import { media, colors } from 'styles'
import { Div } from 'components'
import assets from 'assets'

const mapStateToProps = ({ pages }) => ({ pages })

const contextTypes = {
  media: PropTypes.object
}

const Header = (props, { media }) => (
  <Wrapper column itemsCenter>
    <StaticLink to="/">
      <Logo src={assets.logo} alt="Logo" />
    </StaticLink>

    <Links
      relative
      column={media.smallOnly}
      wraps
      selfStretch
      justifyCenter
      itemsCenter={media.largeUp}
      listRight={media.mediumUp && 60}
    >
      {Object.values(props.pages)
        .filter(page => page.slug !== 'glowna')
        .map(page => (
          <Link key={page.id} to={`/${page.slug}`}>
            {page.title.rendered}
          </Link>
        ))}
    </Links>
  </Wrapper>
)

Header.contextTypes = contextTypes

export default withRouter(connect(mapStateToProps)(Header))

const Wrapper = Div.withComponent('header')

const Logo = styled.img`
  align-self: center;
  width: 100%;
  max-width: 265px;
  margin: 16px;
`

const Links = Div.withComponent('nav').extend`
  min-height: 76px;
  background: ${colors.lightGray};
	font-size: 21px;

  ${media.mediumUp`padding: 0 48px;`}

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
	  width: 100%;
	  height: 2px;
	  background: ${colors.lightGray};
    opacity: 0.6;
    z-index: 1;
  }

  &::before {
    top: -5px;
  }

  &::after {
    bottom: -5px;
  }
`

const Link = styled(NavLink)`
  position: relative;
  padding: 10px;
  white-space: nowrap;
  text-align: center;
  text-transform: lowercase;
  transition: 0.2s;
  z-index: 10;

  ${media.mediumUp`
    margin: -5px 0;
    padding: 31px 10px;
  `}

  &.active {
    color: white;
    background: ${colors.green};
    mix-blend-mode: multiply;
    box-shadow: 1px 2px 2px 0 rgba(38, 38, 38, 0.15);
  }
`
