import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _sortBy from 'lodash.sortby'

import { Section, Container, Heading, SubHeading } from 'components'

const propTypes = {
  items: PropTypes.array
}

const CurrentShopItemsSection = ({ items, date }) => (
  <Section>
    <Container column style={{ maxWidth: '100%' }}>
      <Heading center mTop={20}>Obecnie w sklepiku</Heading>

      <SubHeading center mTop={35}>
        {'' || date?.title.rendered} <strong>{'' || date?.acf.data_aktualizacji}</strong> 
      </SubHeading>

      <ShopTable>
        <thead>
          <tr>
            {/*eslint-disable */}
            <th></th>
            <th>NAZWA</th>
            <th>DOSTĘPNOŚĆ</th>
            <th>CENA</th>
            <th>UWAGI</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {_sortBy(items, 'slug').map((item, i) => (
            <tr key={i}>
              <td></td>
              <td className='item-title'>{item.title.rendered}</td>
              <td>{item.acf.dostepnosc}</td>
              <td className='item-price'>{item.acf.cena}</td>
              <td>{item.acf.uwagi}</td>
              <td></td>
              {/*eslint-enable */}
            </tr>
          ))}
        </tbody>
      </ShopTable>
    </Container>
  </Section>
)

CurrentShopItemsSection.propTypes = propTypes

export default CurrentShopItemsSection

const ShopTable = styled.table`
  font-size: 17px;
  width: 100%;
  margin-top: 50px;
  border-collapse: collapse;

  thead tr {
    color: #6a9b3a;
    height: 60px;
    text-align: center;
    border-bottom: 2px solid #c9c9c9;
  }

  tbody tr {
    text-align: center;
    border-bottom: 2px solid #c9c9c9;

    &:last-child {
      border-bottom: none;
    }

    .item-title {
      color: #6a9b3a;
      font-weight: bold;
    }

    .item-price {
      font-weight: bold;
    }

    td {
      width: 20%;
      padding: 23px 3px;
    }

    td:first-child {
      width: 10%;
    }

    td:last-child {
      width: 10%;
    }

  }

  @media screen and (max-width: 600px) {
    thead tr {
      display: none;
    }

    tbody tr {
      margin-bottom: 10px;

      td {
        width: 100%;
        padding: 0;
      }

      td:first-child {
        padding-top: 23px;
      }

      td:last-child {
        padding-bottom: 23px;
      }
    }

    td {
      display: block;
      margin-bottom: 10px;
    }
  }
`
