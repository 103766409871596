import styled from 'styled-components'

import baseStyles from './TypographyBase'

export default styled.p`
  ${baseStyles}

	font-family: Calibri, sans-serif;
	font-size: 17px;
	line-height: 1.47;
`
