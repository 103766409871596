import _get from 'lodash.get'

export const isNumber = value => typeof value === 'number'

export const isString = value => typeof value === 'string'

export const isAlphaNumeric = value => isString(value) || isNumber(value)

// Used to support number as size values in styled-components
export const withUnit = value => (isNumber(value) ? `${value}px` : value)

// Resolves when image is loaded
export const preloadImage = src =>
  new Promise(resolve => {
    if (!src) return resolve(false)
    const img = new Image()
    img.onload = resolve(src)
    img.src = src
  })

export const getFeaturedMedia = item => _get(item, "_embedded['wp:featuredmedia'][0].source_url")

export const getFeaturedMediaMediumSize = item => _get(item, "_embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url")

export const getError = error => error.response || error.request || error.message || error.config

export const getDateAktualnosci = date => {
  let year = date.slice(0,4)
  let month = date.slice(4,6)
  let day = date.slice(6)

  let data = {
    year, month, day
  }

  return data
}
