import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { media } from 'styles'

const propTypes = {
  style: PropTypes.object
}

const Tractor = (props) => (
  <Link to={`/sklepik`}>
    <TraktorImage
      style={props.style}
      src='https://www.majlert.pl/wordpress/wp-content/uploads/2018/04/majlerty_strona_gazik_zwłosami.png'
    />
  </Link>
)

Tractor.propTypes = propTypes

export default Tractor

const TraktorImage = styled.img`
  position: absolute;
  z-index: 100;
  height: 144px;

  &:hover {
    transform: scale(1.05);
  }

  ${media.mediumDown`height: 100px`}
  ${media.smallOnly`height: 80px`}
`
