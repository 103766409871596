import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'

import { dispatch } from 'store'

import {
  HomePage,
  AboutPage,
  ContactPage,
  ProductsPage,
  ShopPage,
  NewsPage,
  InitiativePage,
  NotFoundPage,
  SingleProductPage,
  SingleNewsPage
} from 'pages'

import { Header, Main, Footer, Spinner, ErrorBoundary } from 'components'

const mapStateToProps = ({ appReady }) => ({ appReady })

class App extends Component {
  static propTypes = {
    appReady: PropTypes.bool.isRequired,
    history: PropTypes.object
  }

  state = {
    firstMount: true
  }

  async componentDidMount () {
    dispatch().fetchInstagram()
    await dispatch().fetchCategories()
    await dispatch().fetchPages()
    dispatch().appReady()

    // This statement is for scrolling view to top everytime when user switches routes
    if (typeof window !== 'undefined') { //incase you have server-side rendering too
        this.state.firstMount && this.props.history.listen((location, action) => {
            setImmediate(() => window.scrollTo(0, 0))
            this.setState({ firstMount: false })
    })
  }

    // TODO: bring this back once the Facebook token refreshing is working

    // window.FB.init({
    //   appId: '459310617843614',
    //   autoLogAppEvents: true,
    //   xfbml: true,
    //   version: 'v3.0'
    // })
    //
    // window.FB.api(
    //   '/296349683776072',
    //   'GET',
    //   {
    //     "fields": "albums{photos{picture,created_time,name,link}}",
    //     access_token : 'EAACEdEose0cBAG0QWbhmsAdNSq07gaC05JxnUqvRHOqrlH6UjtJctgFAAa3WKMlrqvzP623GPtgUZAPcFw09TAS2OwqdheDqZByZAf4nFzlEHS6l3UQ4cPPQ5OitWYWymSVCAv8TaE2oI8yAd0sOqf9aoqa5kZB7k66ES7OTZBhYblIIfhbC7KeK2WhG0urKW3sHh5wKbdAZDZD',
    //   },
    //   response => {
    //     console.log(response)
    //   }
    // )
  }

  render = () =>
    (!this.props.appReady ? (
      <Spinner />
    ) : (
      <Fragment>
        <Header />
        <Main>
          <ErrorBoundary>
            <Switch>
              <Route path="/" exact component={HomePage} />
              <Route path="/o-gospodarstwie" component={AboutPage} />
              <Route path="/kontakt" component={ContactPage} />
              <Route path="/produkty" exact component={ProductsPage} />
              <Route path="/produkty/:id" component={SingleProductPage} />
              <Route path="/sklepik" component={ShopPage} />
              <Route path="/aktualnosci" exact component={NewsPage} />
              <Route path="/aktualnosci/:id" component={SingleNewsPage} />
              <Route path="/inicjatywy" component={InitiativePage} />
              <Route path="*" component={NotFoundPage} />
            </Switch>
          </ErrorBoundary>
        </Main>
        <Footer />
      </Fragment>
    ))
}

export default connect(mapStateToProps)(App)
