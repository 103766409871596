import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class ErrorBoundary extends Component {
  static propTypes = {
    message: PropTypes.string,
    children: PropTypes.node.isRequired
  }

  static defaultProps = { message: 'Error... :(' }

  state = { error: null }

  componentDidCatch = error => this.setState({ error })

  render = () =>
    (!!this.state.error ? (
      <h1 style={{ margin: 'auto', padding: 24, textAlign: 'center' }}>
        {this.props.message}
        <br />
        <br />
        {this.state.error.message}
      </h1>
    ) : (
      this.props.children
    ))
}
