import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { getFeaturedMedia } from 'utils'
import { contentProvider } from 'decorators'
import { HeroImage, Loader } from 'components'

import ObiadyNaPoluSection from './ObiadyNaPoluSection'
import WycieczkiPoPoluSection from './WycieczkiPoPoluSection'
import YouTubeSection from './YouTubeSection'

const PAGE_NAME = 'inicjatywy'

const propTypes = {
  page: PropTypes.object,
  youtubePosts: PropTypes.array,
  // inicjatywyPosts: PropTypes.array,
}

const Page = props => {
  // const noDataInLocalStorage = !(props.inicjatywyPosts.length > 0)
  const noYoutubePostsInLocalStorage = !(props.youtubePosts.length > 0)

  return (
  <Fragment>
    <HeroImage src={getFeaturedMedia(props.page)} />
    {/* This part is commented because I decided to put content in code and not fetch it from WordPress*/}
    {/* <Loader isLoading={noDataInLocalStorage}>
      <InitiativeSection items={props.inicjatywyPosts} />
    </Loader> */}
    <ObiadyNaPoluSection />
    <WycieczkiPoPoluSection />

    <Loader isLoading={noYoutubePostsInLocalStorage}>
      <YouTubeSection youTubeVideos={props.youtubePosts} startedNumberOfVideos={6} />
    </Loader>
  </Fragment>
)}

Page.propTypes = propTypes

export default contentProvider(PAGE_NAME)(Page)
