import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _sortBy from 'lodash.sortby'
import _takeRight from 'lodash.takeright'

import { getFeaturedMediaMediumSize } from 'utils'
import { Section, Container, Row, Col, Product, ButtonLoadMore } from 'components'

import LetterSelector from './LetterSelector'

export default class ProductsSection extends Component {
  static propTypes = {
    products: PropTypes.array
  }

  state = {
    chosenLetter: null,
    products: this.props.products,
    displayedProducts: 12
  }

  handleSelectLetter = chosenLetter => e => {
    if (chosenLetter === this.state.chosenLetter) {
      return this.setState({
        chosenLetter: null,
        products: this.props.products
      })
    }

    // Sorted products by name
    const sortedProducts = _sortBy(this.props.products, product => product.title.rendered)

    // Getting indexOf the first product started with the chosenLetter
    const positionOfFirstProductStartsWithChosenLetter = sortedProducts.findIndex(product => product.title.rendered.toLowerCase().startsWith(chosenLetter.toLowerCase()))

    // Number passed to the _takeRight function to tell how many products display after chosenLetter
    const parameterShowsHowManyProductsDisplay = this.props.products.length - positionOfFirstProductStartsWithChosenLetter

    // Final array withe the choosen products
    const finalOutput = _takeRight(sortedProducts, parameterShowsHowManyProductsDisplay)

    this.setState({
      chosenLetter,
      products: finalOutput,
      displayedProducts: 12
    })
  }

  render = () => (
    <Section white>
      <Container narrow column itemsCenter>
        <LetterSelector products={this.props.products} chosenLetter={this.state.chosenLetter} onClick={this.handleSelectLetter} />

        <Row mTop={48} listBottom={40} width="100%">
          {_sortBy(this.state.products, 'slug').slice(0, this.state.displayedProducts).map(product => (
            <Col key={product.id} column itemsCenter small={12} medium={6} large={4}>
              <Product
                id={product.id}
                img={getFeaturedMediaMediumSize(product)}
                name={product.title.rendered}
                text={product.acf.nazwa_lacinska}
              />
            </Col>
          ))}
        </Row>

        <ButtonLoadMore loadMore={() => this.setState({ displayedProducts: this.state.displayedProducts + 3 })}/>
      </Container>
    </Section>
  )
}
