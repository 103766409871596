import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { urls } from 'config'
import { colors } from 'styles'
import { Div, Container, Row, Col } from 'components'
import assets from 'assets'

const contextTypes = {
  media: PropTypes.object
}

const Footer = (props, { media }) => (
  <Fragment>
    <LineFooter />
    <StyledFooter>
      <Container itemsCenter>
        <Row listBottom={media.mediumDown && 24}>
          <Col small={12} large={2} offsetLarge={2} justifyCenter={media.mediumDown}>
            <img src={assets.whiteLogo} alt="" />
          </Col>
          <Col small={12} large={2} offsetLarge={4} column listBottom={24} itemsCenter>
            <Div listRight={16}>
              <SocialMediaLink href={urls.facebook}>
                <i className="fab fa-facebook-f" />
              </SocialMediaLink>
              <SocialMediaLink href={urls.instagram}>
                <i className="fab fa-instagram" />
              </SocialMediaLink>
              <SocialMediaLink href={urls.youTube}>
                <i className="fab fa-youtube" />
              </SocialMediaLink>
            </Div>
            <ContactLink to="/kontakt">
              kontakt <i className="fas fa-chevron-right" />
            </ContactLink>
          </Col>
        </Row>
      </Container>
    </StyledFooter>
  </Fragment>
)

Footer.contextTypes = contextTypes

export default Footer

const LineFooter = styled.div`
  height: 10px;
  background-color: #fafafa;
`

const StyledFooter = Div.withComponent('footer').extend`
  padding: 42px 0 37px;
  color: white;
  background: ${colors.green};
`

const SocialMediaLink = styled.a.attrs({ target: '_blank' })`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background: white;
  border-radius: 50%;
  color: ${colors.green};
`

const ContactLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 21px;
  line-height: 1.19;

  > svg {
    margin-left: 8px;
  }
`
