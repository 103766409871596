import React from 'react'
import PropTypes from 'prop-types'

import { getFeaturedMedia } from 'utils'
import { Section, Container, Row, Col, Heading, News, Traktor } from 'components'

 const KalendariumSection = props => (
  <Section>
    <Traktor style={{ marginTop: '-50px' }} />
    <Container column itemsCenter style={{ padding: '102px 0'}}>
      <Heading mTop={40}>Aktualności</Heading>
      <Row mTop={10}>
        {props.news.slice(0, 3).map(item => (
          <Col column justifyStart itemsCenter small={12} large={4} mTop={54} key={item.id}>
            <News
              id={item.id}
              data={item.acf.data}
              img={getFeaturedMedia(item)}
              title={item.title.rendered}
              text={item.acf.opis_aktualnosci}
              more='czytaj więcej'/>
          </Col>
        ))}
      </Row>
    </Container>
  </Section>
)

KalendariumSection.propTypes = { news: PropTypes.array }

KalendariumSection.defaultProps = { news: [] }

export default KalendariumSection
