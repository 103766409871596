import styled from 'styled-components'

import baseStyles from './TypographyBase'

export default styled.h1`
  ${baseStyles}

	font-family: HavanaRegular, cursive;
	font-size: 58px;
`
