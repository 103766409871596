import React from 'react'
import PropTypes from 'prop-types'

import { getFeaturedMedia } from 'utils'
import { contentProvider } from 'decorators'
import { HeroImage, Loader } from 'components'

import KalendariumSection from './KalendariumSection'
import RecomendedProductSection from './RecomendedProductSection'
import NewsSection from './NewsSection'
import YouTubeSection from '../inicjatywy/YouTubeSection'

const PAGE_NAME = 'glowna'

const propTypes = {
  page: PropTypes.object,
  aktualnosciPosts: PropTypes.array,
  produktPosts: PropTypes.array,
  youtubePosts: PropTypes.array,
  instagram: PropTypes.array
}

const Page = props => {
  const noAktualnosciPostsInLocalStorage = !(props.aktualnosciPosts.length > 0)
  const noProduktPostsInLocalStorage = !(props.produktPosts.length > 0)
  const noYoutubePostsInLocalStorage = !(props.youtubePosts.length > 0)
  const polecaneProdukty = (props.produktPosts.filter(product => product.acf.polecany))

  return (
  <>
    <HeroImage src={getFeaturedMedia(props.page)} style={{ backgroundPosition: 'center top' }}/>

    <Loader isLoading={noAktualnosciPostsInLocalStorage}>
      <KalendariumSection news={props.aktualnosciPosts}/>
    </Loader>

    <Loader isLoading={noYoutubePostsInLocalStorage}>
      <YouTubeSection youTubeVideos={props.youtubePosts} startedNumberOfVideos={3} />
    </Loader>

    <Loader isLoading={noProduktPostsInLocalStorage}>
      {polecaneProdukty.length > 0 && <RecomendedProductSection products={polecaneProdukty}/>}
    </Loader>

    <NewsSection instagramPhotos={props.instagram} />
  </>
)}

Page.propTypes = propTypes

export default contentProvider(PAGE_NAME, ({ instagram }) => ({ instagram }))(Page)
