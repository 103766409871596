import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import axios from 'axios'

import { urls } from 'config'
import { store } from 'store'
import { MediaQueryProvider } from 'components'

import registerServiceWorker from './registerServiceWorker'
import App from './App'

axios.defaults.baseURL = urls.apiBase

render(
  <Provider store={store}>
    <MediaQueryProvider>
      <Router>
        <Route path="/" component={App} />
      </Router>
    </MediaQueryProvider>
  </Provider>,
  document.getElementById('root')
)

registerServiceWorker()
