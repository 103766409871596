export default {
  APP_READY: 'APP_READY',
  FETCH_CATEGORIES: 'FETCH_CATEGORIES',
  FETCH_PAGES: 'FETCH_PAGES',
  FETCH_POSTS: 'FETCH_POSTS',
  START_REQUEST: 'START_REQUEST',
  STOP_REQUEST: 'STOP_REQUEST',
  SHOW_ERROR: 'SHOW_ERROR',
  HIDE_ERROR: 'HIDE_ERROR'
}
