import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _sortBy from 'lodash.sortby'

import { media } from 'styles'
import { getFeaturedMedia } from 'utils'
import { Section, Container, Div, Heading, Paragraph, WpContent } from 'components'

const TimelineSection = ({ items }, { media }) => {
  const leftColumn = []
  const rightColumn = []
  const singleColumn = _sortBy(items, 'acf.chronologia').map((item, index) => <Item key={item.id} single {...item} />)

  _sortBy(items, 'acf.chronologia').forEach(
    (item, index) =>
      (index % 2
        ? rightColumn.push(<Item key={item.id} right {...item} className="rightColumn"/>)
        : leftColumn.push(<Item key={item.id} left {...item} className="leftColumn"/>))
  )

  return (
    <Section style={{ overflow: 'hidden' }}>
      <Container column itemsCenter listBottom={24}>
        <Heading>Historia</Heading>
        <Paragraph>Historia rodziny i gospodarstwa Majlertów</Paragraph>

        <Div row>
          {media.mediumUp && (
            <Div column flex={1}>
              {leftColumn}
            </Div>
          )}

          {media.mediumUp && (
            <Div column flex={1} mTop={180}>
              {rightColumn}
            </Div>
          )}

          {media.smallOnly && (
            <Div column listBottom={16}>
              {singleColumn}
            </Div>
          )}
        </Div>
      </Container>
    </Section>
  )
}

TimelineSection.propTypes = {
  items: PropTypes.array
}

TimelineSection.defaultProps = {
  items: []
}

TimelineSection.contextTypes = {
  media: PropTypes.object
}

export default TimelineSection

const Item = props => (
  <StyledItem column {...props}>
    <Image src={getFeaturedMedia(props)} />
    <Div mTop={24}>
      <WpContent small html={props.content.rendered} />
      <Year dot={!props.single}>{props.acf.rok}</Year>
    </Div>
  </StyledItem>
)

const Year = styled.span`
  position: relative;

  height: 54px;
  background-color: #74a651;
  padding: 2px 3px;

  font-family: BradleyHandITC;
  font-size: 23px;
  font-weight: bold;
  text-align: center;
  color: white;

  ${media.smallOnly`margin-left: 16px;`}

  ${media.mediumUp`
    &::after {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      position: absolute;
      top: 50%;
      background: #767a7d;
      border-radius: 50%;
    }
  `}
`

const StyledItem = Div.extend`
  max-width: 425px;

  &.leftColumn {
    text-align: right;
    &:last-child {
      ${Year} {
        &::before {
          content: "";
          display: block;
          width: 5px;
          background: #ebebec;
          height: 100vh;
          position: absolute;
          top: 50%;
          right: -28px;
        }
      }
    }
  }

  ${media.mediumUp`
    max-width: 390px;
    padding: 0 24px 20px;
  `}

  ${props => props.left && `
    align-items: flex-end;
    border-right: 2px solid #767a7d;

    > div {
      > div {
        order: 1;
      }
      > span {
        order: 2;
        margin-left: 12px;
        &::after {
          right: -29px;
        }
      }
    }
  `}

  ${props => props.right && `
    align-items: flex-start;
    margin-left: -2px;
    border-left: 2px solid #767a7d;

    > div {
      > div {
        order: 2;
      }
      > span {
        order: 1;
        margin-right: 12px;
        &::after {
          left: -29px;
        }
      }
    }
  `}
`

const Image = styled.img`
  display: block;
  width: 100%;
  max-width: 310px;

  ${media.smallOnly`margin: 0 auto;`}
`
