import styled from 'styled-components'

import { Div } from 'components'

const WpContent = styled(Div).attrs({
  dangerouslySetInnerHTML: props => ({ __html: props.html }),
  column: true
})`
  > *:first-child {
    margin-top: 0;
  }

  p {
    font-size: ${props => (props.small ? '14px' : '17px')};
    line-height: 1.5;
  }
`

export default WpContent
