import React from 'react'
import PropTypes from 'prop-types'

const contextTypes = {
  media: PropTypes.object
}

const YouTubePlayer = (props, {media}) => {
  /**
   * Transforms a JavaScript object into a URL param string
   * E.g. { param1: 1, param2: value2 } -> "param1=1&param2=2")
   * @param params - object with options
   * @returns {string} with the concatenated URL params
   */
   const getUrlParamsString = parameters =>
    Object.keys(parameters)
      .map(key => key + '=' + parameters[key])
      .join('&')

  const youtubePlayerOptions = {
    rel: 0,
    autoplay: 1,
    controls: 2,
    disablekb: 0,
    fs: 1,
    loop: 0,
    modestbranding: 1,
    showinfo: 0,
    mute: 0,
    iv_load_policy: 3,
  }

  const playerHeight = media.smallOnly ? "320" : "100%"

  return (
    <iframe
      width="100%"
      height={playerHeight}
      src={`https://www.youtube.com/embed/${props.url}?${getUrlParamsString(youtubePlayerOptions)}`}
      frameBorder="0"
      allow="autoplay; encrypted-media"
      allowFullScreen
      title={`YouTubeMovie${props.url}`}
      {...props}
    />
  )
}

YouTubePlayer.propTypes = {
  url: PropTypes.string.isRequired
}

YouTubePlayer.contextTypes = contextTypes

export default YouTubePlayer
