import React, { Component } from 'react'
import { connect } from 'react-redux'

import { postCategoriesToFetch } from 'config'
import { dispatch } from 'store'

const MagicStuff = (state, pageName, customMapState) => ({
  page: state.pages[pageName],
  ...(postCategoriesToFetch[pageName] || []).reduce(
    (all, category) => ({
      ...all,
      [`${category}Posts`]: state.posts[category],
      [`${category}PostsFetching`]: state.requests[`${category}PostsFetching`]
    }),
    {}
  ),
  ...customMapState(state)
})

export default (pageName, mapStateToProps = () => ({})) => ComponentToDecorate =>
  connect(state => MagicStuff(state, pageName, mapStateToProps))(
    class Decorator extends Component {
      displayName = ComponentToDecorate.displayName || ComponentToDecorate.name

      static defaultProps = {
        page: {},
        ...(postCategoriesToFetch[pageName] || []).reduce(
          (all, category) => ({
            ...all,
            [`${category}Posts`]: [],
            [`${category}PostsFetching`]: false
          }),
          {}
        )
      }

      componentDidMount () {
        (postCategoriesToFetch[pageName] || []).forEach(category => dispatch().fetchPosts(category))
      }

      render = () => <ComponentToDecorate {...this.props} />
    }
  )
