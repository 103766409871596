import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Section, Container, Row, Col, Div, Heading, YouTubeNews, ButtonLoadMore } from 'components'

import { getFeaturedMedia } from 'utils'

const propTypes = {
  youTubeVideos: PropTypes.array,
  startedNumberOfVideos: PropTypes.number
}

const defaultTypes = {
  youTubeVideos: [],
  startedNumberOfVideos: 0
}

const YouTubeSection = ({ youTubeVideos, startedNumberOfVideos }) => {
  const [displayedVideos, setDisplayedVideos] = useState(startedNumberOfVideos);

    return (
      <Section>
        <Container column itemsCenter style={{ marginTop: 50 }}>
          <Div column itemsCenter>
            <Heading center>Polowe przedszkole</Heading>
          </Div>

          <Row mTop={6} width="100%">
            {youTubeVideos.slice(0, displayedVideos).map(item => (
              <Col column justifyStart itemsCenter small={12} medium={6} large={4} mTop={54} key={item.acf.youtube_id}>
                <YouTubeNews img={getFeaturedMedia(item)} title={item.title.rendered} url={item.acf.youtube_id} date={item.acf.youtube_data} />
              </Col>
            ))}
          </Row>

          <ButtonLoadMore loadMore={() => setDisplayedVideos(displayedVideos + 3)}/>
        </Container>
      </Section>
    )
}

YouTubeSection.propTypes = propTypes
YouTubeSection.defaultTypes = defaultTypes

export default YouTubeSection
