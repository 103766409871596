import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { getFeaturedMedia } from 'utils'
import { contentProvider } from 'decorators'
import { Section, Container, LargeHeading, HeroImage, WpContent, Traktor } from 'components'

import MapHardCode from './MapHardCode'

const PAGE_NAME = 'kontakt'

const propTypes = {
  page: PropTypes.object
}

const Page = props => (
  <Fragment>
    <HeroImage src={getFeaturedMedia(props.page)} />

    <Section>
      <Traktor style={{ marginTop: '-50px' }} />

      <Container column itemsCenter>
        <LargeHeading mTop={31}>{props.page.title.rendered}</LargeHeading>

        <WpContent mTop={26} mBottom={70} html={props.page.content.rendered} />

        <MapHardCode />
      </Container>
    </Section>
  </Fragment>
)

Page.propTypes = propTypes

export default contentProvider(PAGE_NAME)(Page)
